import React from 'react';
import { Text, Box } from '@chakra-ui/react';
import I18n from '../I18n';
import PartnerIcons from '../partnerIcons';

const Partners = () => (
  <Box
    px={{ base: '24px', md: '64px', lg: '120px' }}
    pt={{ base: '80px', md: '160px' }}
    pb={{ base: '80px', md: '160px' }}
    bg="linear-gradient(180deg, #F3F6FA 32.81%, rgba(252, 252, 252, 0.00) 100%)"
  >
    <Box w="100%" maxW="1200px" m="0 auto">
      <Box textAlign="center" mb={{ base: '40px', md: '80px' }}>
        <Text
          color="icon.text"
          fontSize={{ base: '32px', md: '49px' }}
          fontWeight="weight.m"
          lineHeight="120%"
        >
          <I18n id="blocto.wallet.partners.title" />
        </Text>
        <Text
          color="icon.text"
          fontSize={{ base: 'size.body.2', md: '20px' }}
          lineHeight={{ base: '130%', md: '140%' }}
          mx="auto"
          mt={{ base: 'space.m', md: 'space.xl' }}
          maxWidth={{ md: '728px' }}
        >
          <I18n id="blocto.wallet.partners.content" />
        </Text>
      </Box>

      <PartnerIcons />
    </Box>
  </Box>
);

export default Partners;
