import image1 from '../../../../../static/lottie/wallet/gas-1.png';
import image2 from '../../../../../static/lottie/wallet/gas-2.png';
import image3 from '../../../../../static/lottie/wallet/gas-3.png';
import image4 from '../../../../../static/lottie/wallet/gas-4.png';

const gasLottie = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 90,
  w: 1080,
  h: 1080,
  nm: 'Feature 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 402, h: 402, u: '', p: image1, e: 0 },
    { id: 'image_1', w: 421, h: 464, u: '', p: image2, e: 0 },
    { id: 'image_2', w: 421, h: 464, u: '', p: image3, e: 0 },
    { id: 'image_3', w: 653, h: 258, u: '', p: image4, e: 0 },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'Feature1_4',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 13,
              s: [879.426, 327.285, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 39,
              s: [879.426, 319.485, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 65,
              s: [879.426, 337.485, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 89, s: [879.426, 327.285, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [200.574, 200.574, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 8,
              s: [0, 0, 100],
            },
            { t: 13, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'Feature1_2',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [872.06, 651.415, 0], ix: 2, l: 2 },
        a: { a: 0, k: [210.24, 231.698, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 5,
              s: [0, 0, 100],
            },
            { t: 10, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: 'Feature1_1',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [436.518, 474.214, 0], ix: 2, l: 2 },
        a: { a: 0, k: [210.24, 231.698, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [0, 0, 100],
            },
            { t: 5, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'Feature1_3',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [324.263, 839.51, 0], ix: 2, l: 2 },
        a: { a: 0, k: [325.991, 128.47, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 3,
              s: [0, 0, 100],
            },
            { t: 8, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
};

export default gasLottie;
