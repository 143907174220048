import React from 'react';
import { Flex, Image, Text, Box, Tag, useBreakpointValue } from '@chakra-ui/react';
import I18n from '../I18n';
import img from '../../../static/new_images/wallet/new-feature.png';
import tabletImage from '../../../static/new_images/wallet/new-feature-md.png';
import mobileImg from '../../../static/new_images/wallet/new-feature-base.png';

const NewFeature = () => {
  const imageSrc = useBreakpointValue({ base: mobileImg, md: tabletImage, lg: img });
  return (
    <Box
      px={{ base: '24px', md: '64px', lg: '120px' }}
      pt={{ base: '40px', md: '80px', lg: '120px' }}
      pb={{ base: '40px', md: '80px', lg: '120px' }}
      bg="icon.text"
      borderRadius={{ base: '30px', md: '60px' }}
    >
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'flex-start', lg: 'space-between' }}
        alignItems="center"
        width="100%"
        maxWidth="1200px"
        margin="0 auto"
      >
        <Box w={{ base: '100%', lg: '488px' }} mb={{ base: '40px', md: '80px', lg: 0 }}>
          <Tag variant="white" mr="space.s">
            <I18n id="blocto.wallet.newFeatures.tag1" />
          </Tag>
          <Tag variant="white">
            <I18n id="blocto.wallet.newFeatures.tag2" />
          </Tag>
          <Text
            color="font.inverse"
            fontSize={{ base: '24px', md: '49px' }}
            fontWeight="weight.m"
            lineHeight="120%"
            mt="space.xl"
            mb={{ base: 'space.s', md: 'space.xl' }}
          >
            <I18n id="blocto.wallet.newFeatures.title" />
          </Text>
          <Text
            color="font.inverse"
            fontSize={{ base: '16px', md: '20px' }}
            lineHeight={{ base: '130%', md: '140%' }}
          >
            <I18n id="blocto.wallet.newFeatures.content" />
          </Text>
        </Box>

        <Image src={imageSrc} width={{ base: '312px', md: '640px', lg: '520px' }} />
      </Flex>
    </Box>
  );
};

export default NewFeature;
