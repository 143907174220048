import React from 'react';
import { Flex, Box, Text, Tag, Grid, GridItem } from '@chakra-ui/react';
import I18n from '../I18n';
import { AnimationButton } from '../button';
import Card from '../card';
import download from '../../utils/download';
import uxImg from '../../../static/new_images/wallet/ux.png';
import socialLoginImg from '../../../static/new_images/wallet/social-login.png';
import custodyImg from '../../../static/new_images/wallet/custody.png';
import paymasterImg from '../../../static/new_images/wallet/paymaster.png';
import bundleImg from '../../../static/new_images/wallet/bundle.png';
import keyImg from '../../../static/new_images/wallet/key.png';

const CARD_INFO = [
  {
    img: uxImg,
    title: <I18n id="blocto.wallet.features.cardUx.title" />,
    content: <I18n id="blocto.wallet.features.cardUx.content" />,
  },
  {
    img: socialLoginImg,
    title: <I18n id="blocto.wallet.features.cardSocialLogin.title" />,
    content: <I18n id="blocto.wallet.features.cardSocialLogin.content" />,
  },
  {
    img: custodyImg,
    title: <I18n id="blocto.wallet.features.cardCustody.title" />,
    content: <I18n id="blocto.wallet.features.cardCustody.content" />,
  },
  {
    img: paymasterImg,
    title: <I18n id="blocto.wallet.features.cardPaymaster.title" />,
    content: <I18n id="blocto.wallet.features.cardPaymaster.content" />,
  },
  {
    img: bundleImg,
    title: <I18n id="blocto.wallet.features.cardBundle.title" />,
    content: <I18n id="blocto.wallet.features.cardBundle.content" />,
  },
  {
    img: keyImg,
    title: <I18n id="blocto.wallet.features.cardKey.title" />,
    content: <I18n id="blocto.wallet.features.cardKey.content" />,
  },
];

const Features = () => (
  <>
    <Box
      height={{ base: '24px', md: '40px' }}
      bg="background.primary"
      borderRadius={{ base: '30px 30px 0 0', md: '60px 60px 0 0' }}
    />
    <Box
      px={{ base: '24px', md: '64px', lg: '120px' }}
      pt={{ base: '56px', md: '80px', lg: '160px' }}
      pb={{ base: '80px', md: '160px' }}
      bg="background.primary"
    >
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent="space-between"
        w="100%"
        maxW="1200px"
        m="0 auto"
        position="relative"
      >
        <Box mb={{ base: '40px', md: '80px', lg: '0' }}>
          <Box
            width={{ base: '100%', lg: '470px' }}
            position={{ base: 'static', lg: 'sticky' }}
            top={{ base: '110px', md: '112px', lg: '136px' }}
          >
            <Tag variant="blue">
              <I18n id="blocto.wallet.features.tag" />
            </Tag>
            <Text
              color="icon.text"
              fontSize={{ base: '32px', md: '49px' }}
              fontWeight="weight.m"
              lineHeight="120%"
              mt={{ base: 'space.s', md: 'space.xl' }}
              mb="space.xl"
            >
              <I18n id="blocto.wallet.features.title" />
            </Text>
            <AnimationButton onClick={() => download('wallet_feature_block')}>
              <I18n id="blocto.wallet.features.button" />
            </AnimationButton>
          </Box>
        </Box>

        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
          gap={{ base: 'space.s', md: 'space.xl' }}
          maxWidth={{ lg: '642px' }}
        >
          {CARD_INFO.map((card) => (
            <GridItem width="100%" height="100%">
              <Card
                key={card.title}
                size="sm"
                imgSrc={card.img}
                title={card.title}
                content={card.content}
                width="100%"
                height="100%"
              />
            </GridItem>
          ))}
        </Grid>
      </Flex>
    </Box>
  </>
);

export default Features;
