import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import Banner from '../components/wallet/banner';
import Features from '../components/wallet/features';
import Intro from '../components/wallet/intro';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CTA from '../components/wallet/cta';
import Partners from '../components/wallet/partners';
import NewFeature from '../components/wallet/newFeature';
import Spotlight from '../components/wallet/spotlight';
import { AppContext } from '../context/index';

// eslint-disable-next-line no-unused-vars
const WalletPage = ({ intl }) => (
  <AppContext.Consumer>
    {() => (
      <Layout>
        <SEO
          title="An ERC-4337 compatible smart contract wallet | Blocto Wallet"
          description="An ERC-4337 compatible smart contract wallet for EVM and various blockchains. Effortlessly manage assets and flexible gas fees options with social login"
          path="wallet"
        />
        <Banner />
        <Features />
        <Intro />
        <Partners />
        <NewFeature />
        <Spotlight />
        <CTA />
      </Layout>
    )}
  </AppContext.Consumer>
);

WalletPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object.isRequired,
};

export default injectIntl(WalletPage);
