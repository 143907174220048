import image1 from '../../../../../static/lottie/wallet/wallet-1.png';
import image2 from '../../../../../static/lottie/wallet/wallet-2.png';
import image3 from '../../../../../static/lottie/wallet/wallet-3.png';
import image4 from '../../../../../static/lottie/wallet/wallet-4.png';
import image5 from '../../../../../static/lottie/wallet/wallet-5.png';

const walletLottie = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 90,
  w: 1080,
  h: 1080,
  nm: 'Feature 3',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 416, h: 416, u: '', p: image1, e: 0 },
    { id: 'image_1', w: 436, h: 436, u: '', p: image2, e: 0 },
    { id: 'image_2', w: 724, h: 247, u: '', p: image3, e: 0 },
    { id: 'image_3', w: 724, h: 247, u: '', p: image4, e: 0 },
    { id: 'image_4', w: 724, h: 247, u: '', p: image5, e: 0 },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'Feature3_5',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 13,
              s: [880, 434.264, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 39,
              s: [880, 422.264, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 65,
              s: [880, 446.264, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 89, s: [880, 434.264, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [207.917, 207.917, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 8,
              s: [0, 0, 100],
            },
            { t: 13, s: [95, 95, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'Feature3_4',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 11,
              s: [180, 739.014, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 37,
              s: [180, 747.014, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 63,
              s: [180, 731.014, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 89, s: [180, 739.014, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [217.847, 217.847, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 6,
              s: [0, 0, 100],
            },
            { t: 11, s: [95, 95, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: 'Feature3_3',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [701.025, 774.076, 0], ix: 2, l: 2 },
        a: { a: 0, k: [361.661, 123.034, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 8,
              s: [0, 0, 100],
            },
            { t: 13, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'Feature3_2',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [401.644, 526.094, 0], ix: 2, l: 2 },
        a: { a: 0, k: [361.659, 123.034, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 5,
              s: [0, 0, 100],
            },
            { t: 10, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: 'Feature3_1',
      refId: 'image_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [548.619, 278.463, 0], ix: 2, l: 2 },
        a: { a: 0, k: [361.628, 123.385, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [0, 0, 100],
            },
            { t: 5, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
};

export default walletLottie;
