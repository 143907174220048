/* eslint-disable import/no-unresolved */
import React from 'react';
import { Flex, Image, Text, Box, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import I18n from '../I18n';
import 'swiper/css';
import 'swiper/css/pagination';
import Button from '../button';
import spotlight1Img from '../../../static/new_images/wallet/spotlight-1.png';
import spotlight1MdImg from '../../../static/new_images/wallet/spotlight-1-md.png';
import spotlight1BaseImg from '../../../static/new_images/wallet/spotlight-1-base.png';
import spotlight2Img from '../../../static/new_images/wallet/spotlight-2.png';
import spotlight2MdImg from '../../../static/new_images/wallet/spotlight-2-md.png';
import spotlight2BaseImg from '../../../static/new_images/wallet/spotlight-2-base.png';
import spotlight3Img from '../../../static/new_images/wallet/spotlight-3.png';
import spotlight3MdImg from '../../../static/new_images/wallet/spotlight-3-md.png';
import spotlight3BaseImg from '../../../static/new_images/wallet/spotlight-3-base.png';
import CustomLink from '../customLink';

const FeatureCardInfo = [
  {
    icon: spotlight3Img,
    iconTablet: spotlight3MdImg,
    iconMobile: spotlight3BaseImg,
    title:
      'Fully ERC-4337 Compatible: Introducing the Groundbreaking Smart Contract Wallet from Blocto',
    content:
      'Blocto’s fully ERC 4337-compatible smart contract wallet is now available and poised to be a game-changer for the account abstraction space.',
    date: 'Jul 14, 2023',
    link: 'https://blocto.io/crypto-blog/ecosystem/erc-4337-compatible-smart-contract-wallet',
  },
  {
    icon: spotlight2Img,
    iconTablet: spotlight2MdImg,
    iconMobile: spotlight2BaseImg,
    title:
      'Demystifying ERC-4337 & Smart Contract Wallets: 3 Common Misconceptions & 3 Key Challenges',
    content:
      'The article aims to provide developers with a deeper understanding of the misconceptions, challenges, and opportunities surrounding ERC-4337.',
    date: 'June 30, 2023',
    link: 'https://blocto.io/crypto-blog/blockchain-education/demystifying-erc-4337-smart-contract-wallets-3-common-misconceptions-3-key-challenges',
  },
  {
    icon: spotlight1Img,
    iconTablet: spotlight1MdImg,
    iconMobile: spotlight1BaseImg,
    title: 'Smart contract wallet is the future, and Blocto is the hidden gem',
    content:
      'ERC-4337 and account abstraction will unlock the full potential of smart contract wallet, and Blocto has been an account abstraction wallet since 2019.',
    date: 'May 12, 2023',
    link: 'https://blocto.io/crypto-blog/blockchain-education/smart-contract-wallet-is-the-future-and-blocto-is-the-hidden-gem',
  },
];

const Spotlight = () => {
  const isMOBILE = useBreakpointValue({ base: true, md: false, lg: false });
  const iconName = useBreakpointValue({ base: 'iconMobile', md: 'iconTablet', lg: 'icon' });
  const [isDESKTOP] = useMediaQuery('(min-width: 1200px)', {
    ssr: true,
    fallback: false, // return false on the server, and re-evaluate on the client side
  });

  const swiperProps = isDESKTOP
    ? { slidesPerView: 3 }
    : {
        width: '276',
        breakpoints: {
          768: {
            width: 363,
          },
        },
      };

  return (
    <Box
      px={{ base: '24px', md: '64px', lg: '120px' }}
      pt={{ base: '80px', lg: '120px' }}
      pb={{ base: '40px', md: '80px', lg: '120px' }}
    >
      <Box w="100%" maxW="1200px" m="0 auto">
        <Text
          color="icon.text"
          fontSize={{ base: '32px', md: '49px' }}
          fontWeight="weight.m"
          lineHeight="120%"
          mb={{ base: '16px', md: '40px', lg: '56px' }}
          maxWidth={{ base: '328px', lg: '100%' }}
        >
          <I18n id="blocto.wallet.spotlight.title" />
        </Text>

        <Box w="100%">
          <Swiper
            spaceBetween={isMOBILE ? 12 : 24}
            style={{
              paddingBottom: '20px',
              marginBottom: '-20px',
            }}
            {...swiperProps}
          >
            {FeatureCardInfo.map((item) => (
              <SwiperSlide key={item.content}>
                <CustomLink
                  noHoverStyle
                  to={item.link}
                  isExternal
                  gtag={{
                    event: 'bloctoweb_click_link',
                    properties: {
                      location: 'feature_article_block',
                      topic_title: item.title,
                      link_url: item.link,
                      link_domain: new URL(item.link).host,
                    },
                  }}
                  display="flex"
                  flexDirection="column"
                  bg="background.primary"
                  borderRadius="32px"
                  boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.05)"
                  overflow="hidden"
                  h={{ base: '392px', md: '524px' }}
                >
                  <Image borderRadius="0 0 44px 44px" w="100%" src={item[iconName]} maxH="220px" />
                  <Flex
                    flex="1"
                    flexDirection="column"
                    justifyContent="space-between"
                    px="space.m"
                    py="space.xl"
                  >
                    <Box pt="space.4xs" px="space.m">
                      <Text
                        display="-webkit-box"
                        color="icon.text"
                        fontSize={{ base: 'size.body.2', md: '25px' }}
                        fontWeight="weight.m"
                        lineHeight="120%"
                        mb="space.m"
                        overflow="hidden"
                        sx={{
                          '-webkit-line-clamp': '3',
                          '-webkit-box-orient': 'vertical',
                        }}
                      >
                        {item.title}
                      </Text>
                      <Text
                        display="-webkit-box"
                        color="icon.text"
                        fontSize={{ base: 'size.body.3', md: 'size.body.2' }}
                        lineHeight={{ base: '150%', md: '130%' }}
                        opacity="0.5"
                        overflow="hidden"
                        sx={{
                          '-webkit-line-clamp': { base: '2', md: '3' },
                          '-webkit-box-orient': 'vertical',
                        }}
                      >
                        {item.content}
                      </Text>
                    </Box>

                    <Flex align="center" justify="space-between" pl="space.m">
                      <Text color="icon.text" fontSize="13px" lineHeight="150%">
                        {item.date}
                      </Text>
                      <Button
                        variant="plain"
                        withArrowIcon
                        fontSize={{ base: '14px', md: '16px' }}
                        lineHeight="130%"
                      >
                        <I18n id="blocto.common.more" />
                      </Button>
                    </Flex>
                  </Flex>
                </CustomLink>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Box>
  );
};

export default Spotlight;
