import React from 'react';
import { Flex, Text, Box, Image } from '@chakra-ui/react';
import I18n from '../I18n';
import bannerImg from '../../../static/new_images/wallet/banner.png';
import { AnimationButton } from '../button/index';
import download from '../../utils/download';

const Banner = () => (
  <Flex
    height={{ base: '772px', md: '1205px', lg: '770px', '2xl': '1040px' }}
    width="100%"
    px={{ base: '24px', md: '64px', lg: '120px' }}
    pt={{ base: '86px', md: '168px', lg: '112px' }}
    pb={{ base: '80px', md: '64px', lg: '0' }}
    bg="border.highlight"
    position="relative"
    _after={{
      display: 'block',
      content: '""',
      bg: 'border.highlight',
      width: '100%',
      height: { base: '20px', md: '40px' },
      position: 'absolute',
      bottom: { base: '-20px', md: '-40px' },
      left: '0',
      zIndex: '-1',
    }}
  >
    <Flex
      flexDirection={{ base: 'column', lg: 'row' }}
      justifyContent={{ base: 'flex-start', lg: 'space-between' }}
      alignItems="center"
      width="100%"
      maxWidth="1200px"
      margin="0 auto"
    >
      <Image
        src={bannerImg}
        width={{ base: '312px', md: '560px', lg: '500px' }}
        height={{ base: '312px', md: '560px', lg: '500px' }}
        mb={{ md: '40px', lg: 0 }}
      />

      <Box w={{ base: '100%', lg: '474px' }}>
        <Text
          color="font.inverse"
          fontSize={{ base: '44px', md: '72px' }}
          fontWeight="weight.m"
          lineHeight={{ base: '120%', md: '110%' }}
        >
          <I18n id="blocto.wallet.banner.title" />
        </Text>
        <Text
          maxWidth="390px"
          color="font.inverse"
          fontSize={{ base: '16px', md: '20px' }}
          lineHeight={{ base: '130%', md: '140%' }}
          mt="space.s"
          mb="space.xl"
        >
          <I18n id="blocto.wallet.banner.content" />
        </Text>
        <AnimationButton onClick={() => download('main_feature_block')}>
          <I18n id="blocto.wallet.banner.button" />
        </AnimationButton>
      </Box>
    </Flex>
  </Flex>
);

export default Banner;
